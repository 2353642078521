@import url('https://fonts.googleapis.com/css2?family=PT+Serif+Caption&display=swap');

html {
  height: 100%;
}
body,
html {
  margin: 0;
  font-family: sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 15px;
  width: 100%;
  /* background: #2C3333; */
  color: #2C3333;
  color: #00395e;
  background-color: #fffefc;
}

/* Globals */
a,
a:hover,
a:hover h2,
a:focus h2,
a:focus,
a:after,
a::before,
a:before,
a:hover:before,
a:focus:before,
a:hover::before,
a:focus::before,
button,
button:hover,
button:focus,
input,
input:focus,
textarea,
textarea:focus,
input[type="submit"],
input[type="submit"]:hover,
input[type="submit"]:focus,
select:focus,
select {
    text-decoration: none;
    outline: none;
    -webkit-transition: color 0.35s ease;
    -moz-transition: color 0.35s ease;
    -o-transition: color 0.35s ease;
    transition: color 0.35s ease;
}
h1 {
  font-family: 'PT Serif Caption', serif;
  font-weight: 700;
  font-size: 1.5rem;
  line-height: 1rem;
}
a {
  color: #E25822;
  text-decoration: none;
}
a:hover,
a:focus {
  color: #00395e;
}
p a {
  color: #E25822;
  display: inline-block;
  text-decoration: none;
  text-decoration-skip-ink: auto;
  position: relative;
  transition: all .25s ease;
}
p a::after {
    content: "";
    display: block;
    width: 0px;
    height: 1px;
    position: relative;
    bottom: 0.37m;
    background-color: #E25822;
    transition: all .25s ease;
    opacity: 0.5;
}
p a:hover, 
p a:focus, 
p a:active {
    color: #E25822;
    outline: 0px;
}
p a:hover::after, 
p a:focus::after, 
p a:active::after {
    width: 100%;
}
nav a {
  color: #00395e;
}
nav a:hover,
nav a.active {
  color: #E25822;
}
.page {
  display: flex;
  max-width: 760px;
  margin: 8rem auto;
}
.page aside,
.page main {
  padding: 0 2rem;
}
.page aside {
  width: 150px;
}
img.bio {
  border-radius: 50%;
}

/* Navigation */
img.brand {
  border-radius: 50%;
  margin-bottom: 2rem;
  transition: transform .4s ease-in-out;
}
img.brand:hover {
  transform: rotate(360deg);
}
nav ul {
  list-style: none;
  margin: 0;
  padding-left: 0;
}
nav ul li {
  transform: rotate(-90deg);
  padding: 22px 0px 34px;
}
nav ul li a {
  text-decoration: none;
  padding: 10px;
}

/* Routes */
.connect svg {
  padding-right: 8px;
}

@media(max-width: 767px) {
  .page {
    display: block;
    margin: 2em 0;
  }
  .page aside {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  nav ul li {
    transform: none;
    display: inline-block;
    padding: 0;
  }
  img.brand {
    margin-bottom: 0;
  }
}